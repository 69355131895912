<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="$t('bankaccount.name')"
        prop="name"
        :rules="[{ required: true, message: 'The field is required'}]"
      >
        <el-input type="text" v-model="form.name"></el-input>
      </el-form-item>

      <el-button type="primary" @click="(!data)?submitForm('form'):updateForm('form')">Save Register</el-button>
    </el-form>
  </div>
</template>

<script>
import bankAccount from "@/services/api/bankAccount";

export default {
  props: ["data"],
  data() {
    return {
      form: {
        id: null,
        name: null
      }
    };
  },
  mounted() {
    if (this.data) {
      this.form.id = this.data.id;
      this.form.name = this.data.name;
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          bankAccount
            .create(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          bankAccount
            .update(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
